<template>
  <v-list dense class="noPadding" style="height: 100%">
    <template v-for="item in items">

      <v-list-item-group v-if="item.children"
                    v-model="item.model"
                    :key="item.text"
                    :prepend-icon="item.model ? item.icon : item['icon-alt']"
                    :append-icon="item.model ? item.iconend : item['iconend-alt']">
        <v-list-item slot="activator">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(child, i) in item.children"
                     :key="i"
                     :to="child.link"
                     @click="">
          <v-list-item-action v-if="child.icon" class="text-xs-center">
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ child.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item v-else @click="" :to="item.link" :key="item.text">
        <v-list-item-action class="text-xs-center">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item>
            {{ item.text }}
          </v-list-item>
        </v-list-item-content>
      </v-list-item>

    </template>

    <v-list-item style="padding-top: 20px; position: fixed; bottom: 80px;">
      <v-list-item-content>
        <v-list-item-subtitle>Created By: <a href="https://codekonstruct.com/" target="_blank">CodeKonstruct</a></v-list-item-subtitle>
        <v-list-item-subtitle>Version: {{version}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>

  import { sharedJS } from '../../../components/dashboard/sharedJS.js'
    //import { version } from '../../../version.js'
    import pjson from '../../../../package.json'

  export default {

    data() {
      return {
        items: [],
        version: ''

      }
    },
    beforeMount() {

      //Gets Menu Items From SharedJS File
        this.items = sharedJS.mainMenuItems().items
        this.version = pjson.version

    }

  }
</script>
