
export const sharedJS = {

  ///////////////////////////////////////////
  //Returns Main Menu Items
  ///////////////////////////////////////////
  mainMenuItems() {
    return {
      items: [
        //Home
            { icon: 'dashboard', text: 'Dashboard', link: '../../dashboard/home' },
        //Accounts
            { icon: 'account_balance', text: 'Accounts', link: '../../dashboard/account' },

        //Incomes
        {
          icon: 'attach_money',
          'icon-alt': 'fa-money-check-alt',
            text: 'Incomes', link: '../../dashboard/income'
          //children: [
          //  { icon: ' ', text: 'One time Incomes' },
          //  { icon: ' ', text: 'Reccurring Incomes' },
          //  { icon: ' ', text: 'Manage Incomes', link: 'income' },
          //]
        },

        //Expenses
        {
          icon: 'assignment',
          'icon-alt': 'assignment',
            text: 'Expenses', link: '../../dashboard/expense'
          //children: [
          //  { icon: ' ', text: 'One time Expenses' },
          //  { icon: ' ', text: 'Reccurring Expenses' },
          //  { icon: ' ', text: 'Manage Expenses', link: 'expense' },
          //]
        },

        //Transactions
        {
          icon: 'fa-hand-holding-usd',
          'icon-alt': 'fa-hand-holding-usd',
            text: 'Transactions', link: '../../dashboard/transaction'
          //children: [
          //  { icon: ' ', text: 'One time Transactions' },
          //  { icon: ' ', text: 'Reccurring Transactions' },
          //  { icon: ' ', text: 'Manage Transactions', link: 'transaction' },
          //]
        },
        //Budgets
        {
          icon: 'fas fa-calculator',
          'icon-alt': 'fas fa-calculator',
            text: 'Budgets', link: '../../dashboard/budget'
          //children: [
          //  { icon: ' ', text: 'Running' },
          //  { icon: ' ', text: 'Completed' },
          //  { icon: ' ', text: 'Manage Budgets', link: 'budget' }
          //]
        },
        {
          icon: 'fas fa-exchange-alt',
          'icon-alt': 'fas fa-exchange-alt',
          text: 'Transfers',
            link: '../../dashboard/transfer'
        },
        //Reports
        //{
        //  icon: 'fa-chart-pie',
        //  'icon-alt': 'fa-chart-pie',
        //  text: 'Reports',
        //  children: [
        //    { icon: ' ', text: 'Cash Flow' },
        //    { icon: ' ', text: 'Income Report', link: '' },
        //    { icon: ' ', text: 'Expense Report', link: '' },
        //  ]
        //},
        //Savings
        //{
        //  icon: 'fa-wallet',
        //  'icon-alt': 'fa-wallet',
        //  text: 'Savings',
        //  children: [
        //    { icon: ' ', text: 'Savings account' },
        //    { icon: ' ', text: 'Goals' },
        //    { icon: ' ', text: 'Purchase Goals' },
        //    { icon: ' ', text: 'Rainy Day' },
        //  ]
        //},
        //Bills
        //{
        //  icon: 'fa-clipboard-list',
        //  'icon-alt': 'fa-clipboard-list',
        //  text: 'Bills',
        //  children: [
        //    { icon: ' ', text: 'Upcoming' },
        //    { icon: ' ', text: 'Paid' },
        //    { icon: ' ', text: 'Manage' },
        //  ]
        //},
        //Debts
        //{
        //  icon: 'fa-chart-area',
        //  'icon-alt': 'fa-chart-area',
        //  text: 'Debts',
        //  children: [
        //    { icon: ' ', text: 'Upcoming' },
        //    { icon: ' ', text: 'Paid' },
        //    { icon: ' ', text: 'Manage' },
        //  ]
        //}
      ]
    }
  },

  ////////////////////////////////////////////
  //Returns The Number for each Badges
  ////////////////////////////////////////////
  navBadges() {

  }



}
